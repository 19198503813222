$(function () {
    console.log(localStorage.getItem('popup3GDentist'));
    var expirationTime = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // Set expiration time for a week (in milliseconds)

    if (!localStorage.getItem('popup3GDentist') || new Date().getTime() > parseInt(localStorage.getItem('popup3GDentistExpireTime'))) {
        $('#homeModal').modal('show');
        localStorage.setItem('popup3GDentist', '1');
        localStorage.setItem('popup3GDentistExpireTime', expirationTime);
    }
});
