import Swiper, { Navigation, Pagination, EffectFade, Thumbs, Autoplay } from 'swiper';
import GLightbox from 'glightbox';
// import '../util/shyheader';
import '../routes/js/swiper';
import '../routes/js/dialog-cookies';
import '../routes/js/switchers';
import '../routes/js/menu-hovering';
import '../routes/js/menu-mobile';
import Headroom from 'headroom.js';
import sal from 'sal.js';

Swiper.use([Navigation, Pagination, EffectFade, Thumbs, Autoplay]);

export default {
  init() {
    // JavaScript to be fired on all pages


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // //if (!isMobileVersion()) {
    // $('.header').shyheader();
    var myElement = document.querySelector('.header');
    var headroom = new Headroom(myElement);
    headroom.init();
    // //}



    $('h1, h2, h3, h4, h5, h6, p, p span, .box, .card, .wpcf7, .btn, table, ul, .image, .box-count, .item__count, .tab').not('footer h1, .news-row, .no-animate-this, .swiper-slide .box, footer h2, footer h3, footer .box, footer h5, footer h6, footer .card, footer .wpcf7, footer .btn, footer img, footer ul, footer table, footer a, footer p, .swiper-slide a img, .swiper-slide img, .accordion, .accordion-item, .accordion-header, .accordion-collapse, .faq__item, .accordion div, footer span').attr({
      'data-sal': 'fade',
      'data-sal-duration': 600,
    });

    sal({
      once: true,
    });

    function displayModalFromURL() {
      // Get the value of the 'popup' parameter from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const modalId = urlParams.get('popup');

      // If 'popup' parameter is present and corresponds to a modal ID, show the modal
      if (modalId && $('#' + modalId).length > 0) {
        $('#' + modalId).modal('show');
      }
    }

    $(document).ready(function () {
      displayModalFromURL();
    });

    $('.scroll-down').click(function () {
      $('html, body').animate({
        scrollTop: $('section').next('section').offset().top,
      }, 500);
    });

    const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
    <div class="gloader visible"></div>
    <div class="goverlay"></div>
    <div class="gcontainer">
    <div id="glightbox-slider" class="gslider"></div>

    <button class="gbtn gnext slider-button-next social-ellipsis social-ellipsis-btn next-gallery-btn" tabindex="0" aria-label="Next">
    <svg id="next" xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
    <rect id="shutterstock_2075123149" width="74" height="74" rx="37" fill="#fff"/>
    <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(34.055 28.61)">
      <path id="Path_66632" data-name="Path 66632" d="M0,16.779,8.39,8.39,0,0" fill="none" stroke="#0f1a2e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>

</button>
    <button class="gbtn gprev slider-button-prev social-ellipsis social-ellipsis-btn prev-gallery-btn mr-1" tabindex="1" aria-label="Previous">
    <svg id="previous" xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
    <rect id="shutterstock_2075123149" width="74" height="74" rx="37" fill="#fff"/>
    <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(31.555 28.61)">
      <path id="Path_66632" data-name="Path 66632" d="M8.39,16.779,0,8.39,8.39,0" fill="none" stroke="#0f1a2e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>


</button>


    <button class="gclose gbtn social-ellipsis social-ellipsis-btn close-gallery-btn" tabindex="2" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="11.707" height="11.707" viewBox="0 0 11.707 11.707">
    <g id="Group_1835" data-name="Group 1835" transform="translate(-1853.146 -47.146)">
      <line id="Line_211" data-name="Line 211" x2="11" y2="11" transform="translate(1853.5 47.5)" fill="none" stroke="#000" stroke-width="1"/>
      <line id="Line_212" data-name="Line 212" x1="11" y2="11" transform="translate(1853.5 47.5)" fill="none" stroke="#000" stroke-width="1"/>
    </g>
  </svg>
  </button>
    </div>
    </div>`;
    const lightbox = GLightbox({
      lightboxHTML: customLightboxHTML,
      touchNavigation: true,
      loop: true,
    });

    lightbox.on('open', () => {
      const elements = document.querySelectorAll('.glightbox-container');
      console.log(elements);
      if (elements[1]) {
        elements[1].remove();
      }
    });


    $('.scroll-to-section').click(function () {
      var scroll_to = $(this).attr('data-scroll-to')
      $('html, body').animate({
        scrollTop: $(scroll_to).offset().top,
      }, 250);
    });

    $('#navbarToggler').on('show.bs.collapse', function () {
      $('body').css('overflow', 'hidden');
      $('nav.navbar').addClass('menu-open');
    });

    $('#navbarToggler').on('hidden.bs.collapse', function () {
      $('body').css('overflow', '');
      $('nav.navbar').removeClass('menu-open');

      // Reset classes on menu toggle button
      $('.navbar-toggler .menu').removeClass('back active');
    });

    $('.navbar-toggler').click(function () {
      var menu = $(this).find('.menu');
      if (menu.is('.active:not(.back)')) {
        menu.addClass('back').removeClass('active');
      } else if (menu.is('.back')) {
        menu.removeClass('back');
      } else {
        menu.addClass('active');
      }
    });

    /* eslint-disable no-unused-vars */

    var sliderThumbs = new Swiper('.slider__thumbs', {
      slidesPerView: 1,
      spaceBetween: 0,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      breakpoints: {
        992: {
          slidesPerView: 3,
        },
      },
    });

    // $('.navbar-nav>li>a').on('click', function () {
    //   $(this).siblings('.dropdown-menu').toggle();
    // });

    $('.dropdown-item').on('click', function () {
      $('.navbar-collapse').collapse('hide');
      $('nav.navbar').removeClass('menu-open');
      $('.menu').removeClass('active');
      $('.stick').removeClass('open');
      $('.stick').addClass('close');
    });

    $('#navbarToggler').on('show.bs.collapse', function () {
      $('body').css('overflow', 'hidden')
      $('nav.navbar').addClass('menu-open')
    });

    $('#navbarToggler').on('hide.bs.collapse', function () {
      $('body').css('overflow', '')
      $('nav.navbar').removeClass('menu-open')
    });

    $('.navbar-toggler').click(function () {
      var menu = $(this).find('.menu')
      if (menu.is('.active:not(.back)')) {
        menu.addClass('back');
      } else if (menu.is('.back')) {
        menu.removeClass('back');
      } else {
        menu.addClass('active');
      }
    });

    $(document).ready(function () {
      $('.hamburger').click(function () {
        $('.stick').toggleClass(function () {
          return $(this).is('.open, .close') ? 'open close' : 'open';
        });
      });
    });

    var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

    if ('IntersectionObserver' in window) {
      var lazyVideoObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove('lazy');
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }

  },
};
