document.addEventListener('DOMContentLoaded', function () {
    // Get all radio inputs and their corresponding content divs
    var tabs = document.querySelectorAll('input[name="tabs"]');
    var tabs2 = document.querySelectorAll('input[name="tabs2"]');
    var contentDivs = document.querySelectorAll('.animal-section[data-option]');
    var contentDivs2 = document.querySelectorAll('.options-section[data-option]');

    // Add event listener to each radio input
    tabs.forEach(function (tab) {
        tab.addEventListener('change', function () {
            // Hide all content divs
            contentDivs.forEach(function (div) {
                div.classList.add('hidden');
            });

            // Get the selected radio input's data-animal value
            var selectedAnimal = tab.getAttribute('data-option');

            // Show the content div with the corresponding data-animal value
            var selectedDiv = document.querySelector('.animal-section[data-option="' + selectedAnimal + '"]');
            if (selectedDiv) {
                selectedDiv.classList.remove('hidden');
            }
        });
    });

    tabs2.forEach(function (tab) {
        tab.addEventListener('change', function () {
            // Hide all content divs
            contentDivs2.forEach(function (div) {
                div.classList.add('hidden');
            });

            // Get the selected radio input's data-animal value
            var selectedAnimal = tab.getAttribute('data-option');

            // Show the content div with the corresponding data-animal value
            var selectedDiv = document.querySelector('.options-section[data-option="' + selectedAnimal + '"]');
            if (selectedDiv) {
                selectedDiv.classList.remove('hidden');
            }
        });
    });
});