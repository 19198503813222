$('.l2').on('click', function () {
    var tag = $(this).attr('value');
    var back_link = tag;
    $('.back-link').attr('href', back_link);

    $(tag).toggleClass('show-menu');
});

$('.back-link').on('click', function () {
    var tag = $(this).attr('value');
    $(tag).removeClass('show-menu');
});

document.addEventListener('DOMContentLoaded', function () {
    // Sprawdź, czy szerokość ekranu jest mniejsza niż 768 pikseli (standardowa szerokość dla urządzeń mobilnych)
    function closeOtherSubMenus(items, currentItem) {
        items.forEach(function (item) {
            var subMenu = item.querySelector('.sub-menu');
            // Sprawdź, czy aktualny element nie jest tym, który został kliknięty
            if (item !== currentItem) {
                // Jeśli tak, to ukryj podmenu
                subMenu.style.display = 'none';
            }
        });
    }
    if (window.innerWidth < 768) {
        // Znajdź wszystkie główne elementy menu
        var mainItems = document.querySelectorAll('.main-item');

        // Dla każdego głównego elementu menu
        mainItems.forEach(function (item) {
            // Znajdź przyciski wewnętrzne w danym elemencie
            var link = item.querySelector('a');
            var subMenu = item.querySelector('.sub-menu');

            // Dodaj nasłuchiwanie zdarzenia kliknięcia na przycisk
            link.addEventListener('click', function () {
                // Sprawdź, czy subMenu jest aktualnie widoczne
                var isVisible = subMenu.style.display === 'block';

                // Zmiana stanu widoczności na przeciwny
                subMenu.style.display = isVisible ? 'none' : 'block';

                // Jeśli jakiekolwiek podmenu jest otwarte, zamknij je
                closeOtherSubMenus(mainItems, item);
            });
        });
    }
});
