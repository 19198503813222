
// import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

import Swiper from 'swiper/bundle';


var slider1 = new Swiper('.swiper-1', {
    slidesPerView: 1.125,
    spaceBetween: 40,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    breakpoints: {
        992: {
            slidesPerView: 3.125,
            spaceBetween: 30,
        },

        1900: {
            slidesPerView: 3.75,
            spaceBetween: 40,
        },
    },
});

var slider2 = new Swiper('.swiper-2', {
    slidesPerView: 2,
    spaceBetween: 20,
    loop: true,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    breakpoints: {
        992: {
            slidesPerView: 4.5,
            spaceBetween: 40,
        },
    },
    navigation: {
        nextEl: '.next-icon__swiper-2',
        prevEl: '.prev-icon__swiper-2',
    },

});

var slider3 = new Swiper('.swiper-3', {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    navigation: {
        nextEl: '.next-icon__swiper-3',
        prevEl: '.prev-icon__swiper-3',
    },
    pagination: {
        el: '.swiper-pagination',
    },

});

var sliderTeam = new Swiper('.swiper-team', {
    slidesPerView: 1.25,
    spaceBetween: 20,
    loop: false,
    watchOverflow: true,
    centeredSlides: false,
    centeredSlidesBounds: false,
    breakpoints: {
        992: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 4,
        },
    },
    navigation: {
        nextEl: '.next-icon__swiper-team',
        prevEl: '.prev-icon__swiper-team',
    },
});



var slider4 = new Swiper('.swiper-4', {
    slidesPerView: 6,
    spaceBetween: 20,
    loop: true,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    breakpoints: {
        992: {
            slidesPerView: 4.75,
        },
    },
});

var slider5 = new Swiper('.swiper-5', {
    slidesPerView: 1.125,
    spaceBetween: 20,
    loop: false,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    navigation: {
        nextEl: null,
        prevEl: null,
    },
    breakpoints: {
        992: {
            slidesPerView: 3.25,
        },
    },
});

var slider6 = new Swiper('.swiper-6', {
    slidesPerView: 1.125,
    spaceBetween: 20,
    loop: false,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    navigation: {
        nextEl: '.next-icon__swiper-6',
        prevEl: '.prev-icon__swiper-6',
    },
    breakpoints: {
        992: {
            slidesPerView: 2,
        },
    },

});

var slider7 = new Swiper('.swiper-7', {
    slidesPerView: 1.125,
    spaceBetween: 20,
    loop: false,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    breakpoints: {
        992: {
            slidesPerView: 2,
        },
    },
    navigation: {
        nextEl: '.next-icon__swiper-7',
        prevEl: '.prev-icon__swiper-7',
    },
});


console.log(slider1, slider2, slider3, slider4, slider5, slider6, slider7, sliderTeam)